import { template as template_d2a3cd2bf4d3452da9c103c3ffd6f952 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_d2a3cd2bf4d3452da9c103c3ffd6f952(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
