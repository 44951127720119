import { template as template_71566e47896b48fe8f851a2f529f0213 } from "@ember/template-compiler";
const SidebarSectionMessage = template_71566e47896b48fe8f851a2f529f0213(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
