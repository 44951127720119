import { template as template_25972185b0924b92a1e9a7151f884fef } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_25972185b0924b92a1e9a7151f884fef(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
