import { template as template_391caedb6edb4f39ace63515ff5daff6 } from "@ember/template-compiler";
const WelcomeHeader = template_391caedb6edb4f39ace63515ff5daff6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
